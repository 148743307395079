<template>
	<div class="realtrain_view">
		<div class="child-top" style="padding-top: 0;">
			<div class="input-from">
				<el-cascader
					style="float: left;"
					placeholder="请选择运动队"
					clearable
					@change="deparmentChange"
					v-model="deparmentId"
					:options="departmentOptions"
					:show-all-levels="false"
					collapse-tags
					:props="{ multiple: true, children: 'children', label: 'name', value: 'uuid', emitPath: false }"
				></el-cascader>
				<el-select v-model="personUuid" @change="personChange" collapse-tags filterable placeholder="请选择人员" style="width: 160px;">
					<el-option v-for="item in personList" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
				</el-select>
				<el-select v-model="deviceUuid" @change="deviceChange" filterable placeholder="请选择设备">
					<el-option v-for="item in deviceList" :key="item.uuid" :label="item.name" :value="item.id"> </el-option>
				</el-select>
			</div>
			<div class="operation-button">
				<el-button class="new_btn" operation-button round @click="btnClick">
					<span>开始训练</span>
				</el-button>
			</div>
		</div>

		<staffList :listData="tableData" @go-detail="goDetai" :contentText="contentText" :staffLength="staffLength" v-loading="tableLoading" :buttonFlag="buttonFlag"> </staffList>
		<!-- 省时间 没加入组件 -->
		<el-pagination
			:current-page.sync="currentPage"
			:page-size="8"
			:total="tableTotal"
			style="padding-top: 10px"
			@current-change="handleCurrentChange"
			class="table-pagination"
			layout="total, prev, pager, next , jumper"
		></el-pagination>
		<!-- <treeTansfer></treeTansfer> -->

		<el-dialog title="请输入设备号并进行连接" :visible.sync="deviceDialog" :close-on-click-modal="false" :show-close="false" width="50%" center>
			<div class="device_view">
				<!-- <div class="device_flex" v-for="(item, index) in wattSelect" :key="item.uuid">
					<p>姓名: {{ item.name }}</p>
					设备ID：<el-input :v-model="'deviceId' + (index + 1)"></el-input>
				</div> -->
				<div class="device_flex">
					<p>姓名: {{ wattSelect.length == 2 && wattSelect[0].name }}</p>
					设备ID：<el-input v-model="deviceId1"></el-input>
				</div>
				<div class="device_flex">
					<p>姓名: {{ wattSelect.length == 2 && wattSelect[1].name }}</p>
					设备ID：<el-input v-model="deviceId2"></el-input>
				</div>
			</div>
			<div class="form-bottom-button">
				<el-button @click="connect" type="primary" v-no-more-click class="save_btn" round>连接</el-button>
				<el-button @click="cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			personUuid: '',
			personList: [],
			deparmentId: [],
			departmentOptions: [],
			deviceList: [],
			deviceUuid: '',
			tableData: [],
			tableLoading: false,
			deviceDialog: false,
			currentPage: 1,
			tableTotal: 0,

			deviceId1: '',
			deviceId2: '',

			wattSelect: [],

			selectObj: null,
			contentText: [
				{
					name: '运动队',
					field: 'department_name',
				},
			],
			buttonFlag: true,
			staffLength: 2,
		}
	},
	mounted() {
		this.getData()

		// this.getPersion()
		this.$axios.post('/p/staff/tree').then(res => {
			this.departmentOptions = res.data.data.department
		})

		this.$axios
			.post(
				'/p/equipment_hardware/listAll',
				this.$qs({
					data_import: 2,
				})
			)
			.then(res => {
				this.deviceList = res.data.data.rows
				this.deviceUuid = res.data.data.rows[0].id
				this.deviceChange()
			})
	},
	methods: {
		deviceChange() {
			var name = ''
			this.deviceList.forEach(item => {
				if (item.id == this.deviceUuid) {
					name = item.brand_name
				}
			})
			if (name == 'Wattbike') {
				this.staffLength = 2
			} else if (name == 'concept2') {
				this.staffLength = 1
			}
		},
		getPersion() {
			this.$axios
				.post(
					'/p/Staff/getStaffByStation',
					this.$qs({
						department_uuid: this.deparmentId.toString(''),
					})
				)
				.then(res => {
					this.personList = res.data.data
					this.getData()
				})
		},
		getData() {
			this.selectObj = null
			this.$axios
				.post(
					'/p/staff/lists',
					this.$qs({
						department_uuid_str: this.deparmentId.toString(''),
						staff_uuid: this.personUuid,
						page: this.currentPage,
						page_size: 8,
					})
				)
				.then(res => {
					this.tableData = res.data.data.rows
					this.tableTotal = res.data.data.total
					if (this.personUuid) {
						this.selectObj = res.data.data.rows[0]
					}
				})
		},
		deparmentChange() {
			this.personUuid = ''
			this.getData()
			this.getPersion()
		},
		personChange() {
			this.getData()
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getData()
		},
		btnClick() {
			if (this.staffLength == 2) {
				if (!this.wattSelect.length) {
					this.$message({
						type: 'warning',
						message: '请选择人员',
					})
					return false
				} else if (this.wattSelect.length == 1) {
					this.$message({
						type: 'warning',
						message: '请选择两位人员',
					})
					return false
				}
				this.deviceDialog = true
				return false
			}
			if (!this.deviceUuid) {
				return this.$message({
					message: '请选择设备',
					type: 'warning',
				})
			}
			if (!this.selectObj) {
				return this.$message({
					message: '请选择人员',
					type: 'warning',
				})
			}
			window.open(
				'/dist/pm5-base/index.html?staff_uuid=' +
					this.selectObj.uuid +
					'&name=' +
					this.selectObj.name +
					'&sex=' +
					this.selectObj.sex +
					'&old=' +
					this.selectObj.birthday +
					'&department_name=' +
					this.selectObj.department_name +
					'&sport_name=' +
					this.selectObj.sport_name +
					'&coach_name=' +
					this.selectObj.coach_name +
					'&deviceId=' +
					this.deviceUuid
			)
		},
		goDetai(res) {
			if (this.staffLength == 1) {
				this.selectObj = res.length ? res[0] : null
			} else if (this.staffLength == 2) {
				this.wattSelect = res
			}
		},
		connect() {
			// var str = encodeURI(
			// 'http://fc.kxunpt.cn:8090/?id1=' +
			// this.deviceId1 +

			var str = encodeURIComponent(
				JSON.stringify([
					{ name: this.wattSelect[0].name, id: this.deviceId1, uuid: this.wattSelect[0].uuid },
					{ name: this.wattSelect[1].name, id: this.deviceId2, uuid: this.wattSelect[1].uuid },
				])
			)
			// '?data=&id2=' + this.deviceId2 + '&id1Uuid=' + this.wattSelect[0].uuid + '&id2Uuid=' + this.wattSelect[1].uuid + '&name1=' + this.wattSelect[0].name + '&name2=' + this.wattSelect[1].name
			// )
			console.log(str)
			console.log('http://fc.kxunpt.cn:8090/?data=' + str)
			window.open('http://fc.kxunpt.cn:8090/?data=' + str)
		},
		cancel() {
			this.deviceDialog = false
			this.deviceId1 = ''
			this.deviceId2 = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.realtrain_view {
	padding-top: 10px;
	.child-top {
		border-radius: 5px;
		margin-bottom: 10px;
		.input-from {
			float: left;
			min-width: 1150px;
			margin-bottom: 0;

			.picker_date {
				float: left;
				margin-top: 20px;
				width: 240px;
			}

			& > .el-select {
				width: 150px;
				float: left;
				margin-top: 20px;
			}

			.el-select,
			.el-input {
				margin-top: 20px;
			}

			.el-input.el-input--suffix.left-child-input {
				float: left;
				width: 200px;
			}

			.el-cascader {
				margin-top: 18px;
				min-width: 150px;
			}

			.searchBtn {
				margin-top: 20px;
			}
			.el-date-editor {
				border-bottom: none;
			}
		}

		.operation-button {
			// float: left;
			margin-bottom: 10px;
		}
	}
	.staffList {
		background: #fff;
	}

	.el-pagination {
		background: #fff;
		margin-top: 0;
		padding-bottom: 10px;
	}
}

.device_view {
	.device_flex {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		margin-bottom: 20px;
		p {
			margin-right: 50px;
			flex: 1;
		}
		.el-input {
			width: 50%;
			// margin-left: 20px;
		}
	}
}
</style>
