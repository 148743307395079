import { render, staticRenderFns } from "./RealTraining.vue?vue&type=template&id=6235880c&scoped=true"
import script from "./RealTraining.vue?vue&type=script&lang=js"
export * from "./RealTraining.vue?vue&type=script&lang=js"
import style0 from "./RealTraining.vue?vue&type=style&index=0&id=6235880c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6235880c",
  null
  
)

export default component.exports